@import '~antd/dist/antd.dark.less';

@primary-color: #3cc8fc;
@component-background: #ffffff;
@popover-background: #ffffff;
@popover-shadow: none;

.ant-popover-inner {
  box-shadow: none;
}
.ant-message {
  .ant-message-notice {
    .ant-message-notice-content {
      background-color: #94a716;
      border-radius: 4px;

      .ant-message-custom-content {
        span {
          color: white;
        }
      }
    }

    &.ant-custom-error {
      .ant-message-notice-content {
        background-color: #ff4d4f;

        .ant-message-custom-content {
          span {
            color: white;
          }
        }
      }
    }

    &.ant-custom-warning {
      .ant-message-notice-content {
        background-color: #faad14;

        .ant-message-custom-content {
          span {
            color: white;
          }
        }
      }
    }
  }
}

.ant-checkbox {
  border-radius: 3px;
}

.ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-radius: 0;
  border-color: #20232e;
  // background-color: #20232e;
  &:after {
    border-color: #212330;
  }
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #212330;
  width: 6px;
  height: 11px;
  top: 7px;
}

.ant-checkbox-checked::after {
  border-radius: 3px;
}

.text-primary {
  color: #3cc8fc !important;
}

.text-uppercase {
  text-transform: uppercase;
}

::placeholder {
  color: rgba(51, 51, 51, 0.5);
}

:-ms-input-placeholder {
  color: rgba(51, 51, 51, 0.5);
}

::-ms-input-placeholder {
  color: rgba(51, 51, 51, 0.5);
}

.custom-dropdown {
  .ant-dropdown-menu-item {
    // color: rgba(255, 255, 255, 0.6);
  }
}

.ant-modal-mask {
  background-color: rgb(0 0 0 / 79%) !important;
}

